import {APP_PREFIX_PATH} from "../AppConfig";
import React from "react";

export const courriers = [
    {
        key: "my-affects",
        path: `${APP_PREFIX_PATH}/mes-affectations`,
        component: React.lazy(() => import("views/app-views/courrier/myAffects")),
        meta: {
            permission: "MES_AFFECTATIONS"
        }
    },

    {
        key: "my-records",
        path: `${APP_PREFIX_PATH}/mes-enregistrements`,
        component: React.lazy(() => import("views/app-views/courrier/myRecords")),
        meta: {
            permission: "MES_ENREGISTREMENTS"
        }
    },

    {
        key: "courriers",
        path: `${APP_PREFIX_PATH}/courriers/:type`,
        component: React.lazy(() => import("views/app-views/courrier/courriers")),
    },

    {
        key: "courrier-create",
        path: `${APP_PREFIX_PATH}/courrier/:type`,
        component: React.lazy(() => import("views/app-views/courrier/type")),
    },

    {
        key: "courrier-show",
        path: `${APP_PREFIX_PATH}/courrier/:code/show`,
        component: React.lazy(() => import("views/app-views/courrier/show")),
        meta: {
            permission: "VIEW_COURRIER"
        }
    },
]