import React from 'react'
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'antd/dist/reset.css'

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

// if ('serviceWorker' in navigator) {
//     navigator.serviceWorker
//         .register(`${process.env.PUBLIC_URL}/websocket-sw.js`)
//         .then((registration) => {
//             console.log('Service Worker enregistré:', registration);
//         })
//         .catch((err) => {
//             console.log('Erreur lors de l\'enregistrement du Service Worker:', err);
//         });
// }

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
