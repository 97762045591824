import React from 'react'
import {AUTH_PREFIX_PATH, APP_PREFIX_PATH} from 'configs/AppConfig'
import {roles} from "./routes/roles";
import {correspondants} from "./routes/correspondants";
import {sections} from "./routes/sections";
import {fonctions} from "./routes/fonctions";
import {personnels} from "./routes/personnels";
import {uas} from "./routes/uas";
import {courriers} from "./routes/courriers";
import {organigrammes} from "./routes/organigrammes";
import {archivage} from "./routes/archivage";

export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
    {
        key: 'forgot-password',
        path: `${AUTH_PREFIX_PATH}/forgot-password`,
        component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
    },
]

export const protectedRoutes = [
    {
        key: 'dashboard',
        path: `${APP_PREFIX_PATH}`,
        component: React.lazy(() => import('views/app-views')),
    },

    {
        key: 'profile',
        path: `${APP_PREFIX_PATH}/profile`,
        component: React.lazy(() => import('views/app-views/profile')),
    },

    {
        key: 'notifications',
        path: `${APP_PREFIX_PATH}/notifications`,
        component: React.lazy(() => import('views/app-views/profile/notifications')),
    },


    ...archivage,
    ...courriers,
    ...uas,
    ...personnels,
    ...fonctions,
    ...sections,
    ...correspondants,
    ...organigrammes,
    ...roles,

    {
        key: 'users',
        path: `${APP_PREFIX_PATH}/users`,
        component: React.lazy(() => import('views/app-views/user')),
    },
]